.EachMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    overflow-wrap: break-word;
}
.EachMessage--me {
    display: flex;
    justify-content: end;
}
.EachMessage--you {
    display: flex;
    justify-content: start;
}
.EachMessage .EachMessage__inner {
    min-width: 100px;
    max-width: 500px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    gap: 10px;
}
.EachMessage .EachMessage__inner--me {
    background-color: lime;
}
.EachMessage .EachMessage__inner--you {
    background-color: rgba(0, 0, 0, .1);
}
.EachMessage .EachMessage__link {
    text-decoration: none;
}
.EachMessage .EachMessage__editForm {
    background-color: #EEE;
    width: 500px;
}
.EachMessage .EachMessage__user {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 10px;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.EachMessage .EachMessage__user--right {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 10px;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.EachMessage .EachMessage__innerQuestionTitle {
    width: 490px;
    background-color: white;
    font-size: 10px;
    padding: 5px;
    border-radius: 5px;
}