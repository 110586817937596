.MessageInput {
    border: 1px solid rgba(0, 0, 0, .1);
    margin-top: 20px;
    padding: 10px 5px;
}
.MessageInput .MessageInput__main {
    display: flex;
    justify-content: space-around;
}
.MessageInput .MessageInput__textarea {
    margin: 10px 0;
}
.MessageInput .MessageInput__buttons {
    display: flex;
    justify-content: space-between;
}
.MessageInput .MessageInput__subTitle {
    margin-left: 10px;
    font-size: 10px;
}