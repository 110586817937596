.SignUp {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
}
.SignUp .SignUp__main {
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.SignUp .SignUp__form {
    width: 500px;
}

.SignUp .SignUp__button {
    width: 250px;
    margin-bottom: 20px;
}