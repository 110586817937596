.MyPage {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
    padding-bottom: 20px;
}
.MyPage .MyPage__main {
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.MyPage .MyPage__title {
    text-align: center;
}
.MyPage .MyPage__room {
    padding: 10px;
    border: 1px solid #CCC;
    border-radius: 10px;
    margin-bottom: 10px;
}
.MyPage .MyPage__link {
    color: black;
    text-decoration: none;
}
.MyPage .MyPage__link:hover {
    color: black;
    opacity: 0.7;
}
.MyPage .MyPage__users {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}
.MyPage .MyPage__user {
    display: flex;
    flex-flow: row;
    width: 45%;
    padding: 10px;
    border: 1px solid #CCC;
    margin-bottom: 10px;
    border-radius: 10px;
}
.MyPage .MyPage__userImage {
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
}
.MyPage .MyPage__roomMessage {
    font-size: 10px;
    color: #555;
}
.MyPage .MyPage__select {
    margin-bottom: 10px;
}
