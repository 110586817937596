.Privacy {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
}
.Privacy .Privacy__main{
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.Privacy .Privacy__main p {
    line-height: 1.6;
}

.Privacy .Privacy__main ul {
    line-height: 1.6;
}