.Usage {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
}

.Usage .Usage__main {
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.Usage .Usage__step {
    padding: 20px;
    border: 1px solid #CCC;
    width: 80%;
    margin: 0 5%;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
}

.Usage .Usage__title {
    margin: 10px 0 5px;
}

.Usage .Usage__subTitle {
    margin: 0 0 10px;
}

.Usage .usage_smallTitle {
    color: #888;
    font-size: 11px;
}

.Usage .Usage__text {
    text-align: left;
    font-size: 12px;
}

.Usage .Usage__icon {
    font-size: 100px;
}

.Usage .Usage__image {
    margin: 20px 0;
}