.QuestionFilter .QuestionFilter__searchForm {
    width: 350px;
}
.QuestionFilter .QuestionFilter__questionTypes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}
.QuestionFilter .QuestionFilter__questionType {
    border: 1px solid #CCC;
    border-radius: 5px;
    padding: 5px 10px;
}
.QuestionFilter .QuestionFilter__question {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px auto;
}
.QuestionFilter .QuestionFilter__quetionLeft {
    flex-basis: calc(100% - 100px);
}
.QuestionFilter .QuestionFilter__question--selected {
    color: red;
}
.QuestionFilter .QuestionFilter__subTitle {
    font-size: 10px;
}
.QuestionFilter .QuestionFilter__questionTypeSmall {
    color: #999;
}