.Room {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
    padding-bottom: 20px;
}
.Room .Room__main {
    background-color: white;
    margin: 20px auto;
    width: 800px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.Room .Room__title {
    display: flex;
    flex-flow: row;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
}