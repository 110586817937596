.RegisterFollowup {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
}

.RegisterFollowup .RegisterFollowup__main {
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.RegisterFollowup .RegisterFollowup__form {
    width: 350px;
}

.RegisterFollowup .RegisterFollowup__userForm {
    border: 1px solid #AAA;
    padding: 0px 10px 10px;
    border-radius: 10px;
    margin-top: 15px;
}

.RegisterFollowup .RegisterFollowup__followup1Explain {
    background-color: #DDD;
    padding: 15px 30px;
    border-radius: 20px;
}