.InviteRegisteredUser {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
}

.InviteRegisteredUser .InviteRegisteredUser__main {
    background-color: white;
    margin: 20px auto;
    width: 500px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.InviteRegisteredUser .InviteRegisteredUser__form {
    margin-top: 10px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.InviteRegisteredUser .InviteRegisteredUser__textField {
    margin: 10px 0;
    width: 350px;
}