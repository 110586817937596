.Header .Header__mainTitle {
    text-decoration: none;
    color: white;
}
.Header .Header__mainTitle:hover {
    text-decoration: none;
    color: white;
}

.Header .Header__subTitle {
    text-decoration: none;
    color: white;
}
.Header .Header__subTitle:hover {
    text-decoration: none;
    color: white;
}