.Message {
    background-color: #f4f4f9;
    min-height: 100vh;
    height: 100%;
    padding-bottom: 20px;
}
.Message .Message__main {
    background-color: white;
    margin: 20px auto;
    width: 800px;
    padding: 10px 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.Message .Message__question {
    border: 1px solid #CCC;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.Message .Message__questionTypes {
    margin-bottom: 10px;
}
.Message .Message__user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2px;
    margin-bottom: 5px;
}
.Message .Message__content {
    background-color: #CCC;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    line-height: 1.8;
}
.Message .Message__form {
    width: 450px;
}