.Top .Top__header {
    padding: 0 20px;
    height: 400px;
    gap: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Top .Top__headerIcon {
    font-size: 200px;
    color: orange;
}
.Top .Top__section1 {
    background-color: rgb(247, 249, 249);
    padding: 40px 20px;
}
.Top .Top__section1Title {
    text-align: center;
}
.Top .Top__section1Content {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin-top: 50px;
    text-align: center;
}
.Top .Top__section1Text {
    text-align: left;
    line-height: 1.5;
}
.Top .Top__section1Answer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    margin: 0 20px;
    color: orange;
}
.Top .Top__section1ArrowRight {
    font-size: 50px;
}
.Top .Top__section1AnswerText {
    margin: 0px;
    font-size: 30px;
}
.Top .Top__section2 {
    background-color: white;
    padding: 40px 20px;
}
.Top .Top__section2Title {
    text-align: center;
    margin: 0 0 40px;
}
.Top .Top__section2Content {
    display: flex;
    gap: 30px;
}
.Top .Top__section2step {
    padding: 20px;
    border: 1px solid #CCC;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
}

.Top .Top__section2title {
    margin: 10px 0 5px;
}

.Top .Top__section2subTitle {
    margin: 0 0 10px;
    line-height: 1.5;
}

.Top .Top__section2smallTitle {
    color: #888;
    font-size: 11px;
}

.Top .Top__section2text {
    text-align: left;
    font-size: 12px;
}

.Top .Top__section2icon {
    font-size: 100px;
}

.Top .Top__section2image {
    margin: 20px 0;
}